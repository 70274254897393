import { Vue, setup, Options } from "vue-class-component";
import { Tab } from '@cems-eco/core/src/models';
import component from "../components/component";
import { widget } from "@cems-eco/core/src/utils/widget";
import { Client, clientFactory } from "../services";

@Options({
    components: component
})
export default class ViewClientPage extends Vue {
    clientBloc = setup(() => clientFactory())
    tabs: Tab
    activeTabIndex: number = 0;
    client = new Client

    async created() {
        this.client.id = this.$route.params.id;

        this.setTabs();
    }

    setTabs() {
        this.tabs = new Tab
        this.tabs.list = [
            {
                id: 'information',
                name: 'Information',
                icon: 'info',
                active: true,
                component: 'InformationViewComponent'
            },
            {
                id: 'management',
                name: 'Management',
                icon: 'users-cog',
                active: false,
                component: 'ManagementViewComponent'
            },
            {
                id: 'site',
                name: 'Site',
                icon: 'industry',
                active: false,
                component: 'SiteViewComponent'
            },
            {
                id: 'equipment',
                name: 'Equipment',
                icon: 'tools',
                active: false,
                component: 'EquipmentViewComponent'
            },
            {
                id: 'sensor',
                name: 'Sensor',
                icon: 'wave-square',
                active: false,
                component: 'SensorViewComponent'
            }
        ];
    }

    onTabChanged(index: number) {
        this.activeTabIndex = index;
    }

    async deleteData(data: any) {
        widget.alertDelete().then(async (result) => {
            try {
                if (!result.isConfirmed) {
                    widget.alertSuccess('Deletion abort.', 'Your data is save.');
                    return;
                }
                let message = await this.clientBloc.destroyClient(this.client.id);
                widget.alertSuccess('Good Job!', 'Your data has been deleted.');
                this.$router.replace('/client');
            } catch (error) {
                widget.alertError(error);
            }
        })
    }
}
