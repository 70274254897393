import { Vue, setup, Options } from "vue-class-component";
import component from "../components/component";
import { dashboardFactory } from "../services";
import { widget } from "@cems-eco/core/src/utils/widget";
import { isEmpty } from "lodash";

@Options({
    components: component
})
export default class DashboardIndexPage extends Vue {
    clientBloc = setup(() => dashboardFactory())
    response: any = null;
    async created() {
        const loading = await widget.loading();
        loading.showLoading()
        this.response = await this.clientBloc.getAnalytic();
        loading.close();
    }

    checkResponse() {
        return !isEmpty(this.response);
    }
}