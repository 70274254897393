import { dashboardApi } from "./index"
import environment from "@/config"

export function dashboardFactory() {
    const module = 'client';
    const api = dashboardApi;
    const base_url = environment.API_URL + '/' + module;

    async function getAnalytic() : Promise<any> {
        let response = await api.analytic()
        return response.data;
    }

    return {
        api,
        base_url,
        getAnalytic,
    }

}

