import { Vue, Options } from 'vue-class-component';
import { Tab } from "@cems-eco/core/src/models";
import component from "../components/component";
@Options({
    components: component
})
export default class EditClientPage extends Vue {
    tabs: Tab
    activeTabIndex: number = 0;
    
    async created() {
        this.setTabs();
    }

    setTabs() {
        this.tabs = new Tab
        this.tabs.list = [
            {
                id: 'information',
                name: 'Information',
                icon: 'info',
                active: true,
                component: 'InformationEditComponent'
            },
            {
                id: 'management',
                name: 'Management',
                icon: 'users-cog',
                active: false,
                component: 'ManagementEditComponent'
            }
        ];
    }

    onTabChanged(index: number) {
        this.activeTabIndex = index;
    }
}

