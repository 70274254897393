import { api } from '@cems-eco/core/src/services/api';

export class DashboardApi {
    private BASE_URL: string = "/dashboard";

    
    constructor() {
    }

    async analytic(): Promise<any> {
        return await api.get<any>(this.BASE_URL + "/analytic", true);
    }

}

const dashboardApi = new DashboardApi();
export { dashboardApi };
