import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container-fluid mt--6" }
const _hoisted_2 = {
  key: 0,
  class: "row"
}
const _hoisted_3 = { class: "col-md-12" }
const _hoisted_4 = { class: "col-md-8 h-100" }
const _hoisted_5 = { class: "col-md-4 h-100" }
const _hoisted_6 = { class: "col-md-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardInfoComponent = _resolveComponent("DashboardInfoComponent")!
  const _component_DashboardActivityComponent = _resolveComponent("DashboardActivityComponent")!
  const _component_DashboardRegisteredComponent = _resolveComponent("DashboardRegisteredComponent")!
  const _component_DashboardSiteComponent = _resolveComponent("DashboardSiteComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.checkResponse())
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_DashboardInfoComponent, {
              options: _ctx.response.info
            }, null, 8, ["options"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_DashboardActivityComponent, {
              options: _ctx.response.activity
            }, null, 8, ["options"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_DashboardRegisteredComponent, {
              options: _ctx.response.registered
            }, null, 8, ["options"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_DashboardSiteComponent, {
              options: _ctx.response.site
            }, null, 8, ["options"])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}