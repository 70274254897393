import { Vue, setup, Options } from "vue-class-component";
import { Props } from "../../services";
import { isEmpty } from 'lodash';
export default class DashboardSiteComponent extends Vue.with(Props) {
    
  activities: object[] = []
  dtOptions = {}
  events: object[] = []
  dt: any;

  mounted() {
      this.builDataTable();
  }


  async builDataTable() {
    const data = this.options.map((row: any) => {
        return [
            row.client_name,
            row.name,
        ];
    }).filter((row: any) => {
        return !isEmpty(row[0]);
    });
      this.dtOptions = {
        mobileView: {
            header: {
                title: function (data: any) {
                    return "Site Detail"
                },
            }
        },
        columns: [
            { mobileView: true },
            { mobileView: true },
        ],
          data
      };
  }

  dtInstance(dt) {
      this.dt = dt;
  }
}