import { prop } from "vue-class-component";
export interface DashboardAnalytic {
    id: any;
    name: string
    description: string
}


export class Props {
    options = prop({
        type: Object,
    })
}