import { Vue, setup } from "vue-class-component";
import { Props } from "../../services";
import moment from 'moment';

export default class DashboardActivityComponent extends Vue.with(Props) {

    activities: object[] = []
    dtOptions = {}
    events: object[] = []
    dt: any;

    mounted() {
        this.builDataTable();
    }


    async builDataTable() {
        const data = this.options.map((row: any) => {
            return [
                row.user.name,
                row.activity,
                moment(row.created_at).format('DD/MM/YYYY h:mm:ss'),
            ];
        });
        this.dtOptions = {
            mobileView: {
                header: {
                    title: function (data: any) {
                        return data[0]
                    },
                }
            },
            columns: [
                { mobileView: false },
                { mobileView: true },
                { mobileView: true },
            ],
            data: data,
        };

    }

    dtInstance(dt) {
        this.dt = dt;
    }
}