import DashboardRegisteredComponent from "./registered/registered.component.vue";
import DashboardInfoComponent from "./info/info.component.vue";
import DashboardActivityComponent from "./activity/activity.component.vue";
import DashboardSiteComponent from "./site/site.component.vue";


export default {
    DashboardRegisteredComponent,
    DashboardInfoComponent,
    DashboardActivityComponent,
    DashboardSiteComponent
}