import { Vue } from "vue-class-component";
import { Props } from "../../services";
import moment from "moment";

export default class DashboardRegisteredComponent extends Vue.with(Props) {

  option() {
    const labels: any = [];
    const counts: any = [];
    for (const key in this.options) {
      labels.push(key);
      counts.push(this.options[key]);
    }
    const data = {
      labels: labels,
      datasets: [
        {
          label: moment().format("Y"),
          data: counts,
          borderColor: "#5e72e4",
          backgroundColor: "#5e72e4",
        }
      ]
    };
    return {
      type: 'bar',
      data: data,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
          labels: {
              fontColor: '#fff'
          }
      },

        scales: {
          yAxes: [{
            stacked: true,
            gridLines: {
              display: false,
              drawOnChartArea: false
            },
            ticks: {
              display: false,
                // fontColor: '#fff'
            }
          }],

          xAxes: [
            {
              stacked: true,
              gridLines: {
                display: true,
                drawOnChartArea: false
              },
              ticks: {
                display: true,
                // fontColor: '#fff'
              },
            }
          ],


        },
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'New Site'
          }
        }
      },
    };
  }
}